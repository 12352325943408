import axios from "axios";

import { createEdge } from "./edgeController.js";
import { formatFollower } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;

const createFollower = async (createData) => {
  try {
    const urlToQuery = `${baseUrl}/follower`;
    const axiosResponse = await axios.post(urlToQuery, createData);
    const { data } = axiosResponse;
    return formatFollower(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateFollower = async (followerId, followedId, isFollowingActive) => {
  try {
    if (!followerId || !followedId || isFollowingActive === undefined) {
      throw new Error("Missing required parameters in updateFollower");
    }
    const data = {
      followerId: followerId,
      followedId: followedId,
      isFollowingActive: isFollowingActive,
    };
    const urlToQuery = `${baseUrl}/follower`;
    const updatedFollower = await axios.patch(urlToQuery, data);
    return updatedFollower;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const doesFollowerEntryExist = async (followerId, followedId) => {
  try {
    if (!followerId || !followedId) {
      throw new Error("Missing required parameters in doesFollowerEntryExist");
    }
    const urlToQuery = `${baseUrl}/follower?followerId=${followerId}&followedId=${followedId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const follow = async (followerId, followedId) => {
  try {
    if (!followerId || !followedId) {
      throw new Error("Missing required parameters in follow");
    }
    const followerEntry = await doesFollowerEntryExist(followerId, followedId);
    if (followerEntry && !followerEntry?.isFollowingActive) {
      await updateFollower(followerId, followedId, true);
      await createEdge(followerId, followedId, "ARTIST", "FOLLOW", {});
    } else if (!followerEntry) {
      await createFollower({
        followerId: followerId,
        followedId: followedId,
        isFollowingActive: true,
        createdAt: new Date(),
      });
      await createEdge(followerId, followedId, "ARTIST", "FOLLOW", {});
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

const unfollow = async (followerId, followedId) => {
  try {
    if (!followerId || !followedId) {
      throw new Error("Missing required parameters in unfollow");
    }
    const followerEntry = await doesFollowerEntryExist(followerId, followedId);
    if (followerEntry && followerEntry?.isFollowingActive) {
      await updateFollower(followerId, followedId, false);
      await createEdge(followerId, followedId, "ARTIST", "UNFOLLOW", {});
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getAllFollowing = async (followerId) => {
  try {
    if (!followerId) {
      throw new Error("Missing required parameters in allFollowing");
    }
    const urlToQuery = `${baseUrl}/following?followerId=${followerId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export { doesFollowerEntryExist, follow, unfollow, getAllFollowing };
